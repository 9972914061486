import logoWhatsApp from './../imagens/whatsapp-icone-4.png';
import logoMail from './../imagens/gmail-4561841_960_720.webp';
import './Home.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import MaterialIcon from '@material/react-material-icon';


import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


import img1 from './../imagens/2022-03-07.jpg';
import img2 from './../imagens/2022-03-07 (1).jpg';
import img3 from './../imagens/20190130_172048.jpg';
import img4 from './../imagens/20190130_172202.jpg';
import img5 from './../imagens/20190318_122954.jpg';

function Home() {

   
    return (

        <div>
            <div>

            <div className="containerInsidePanel">

                <div className="container m-auto">
                <h1>
                    Associação dos Amigos Una Viva
                </h1>
                <label>Preservando a sustentabilidade do Rio Una e sua foz</label>
                </div>

            </div>

            <Carousel
                className="Carousel"
                dynamicHeight="true"
                autoPlay={true}
                showArrows={false}
                showStatus={false}
                infiniteLoop={true}
                showThumbs={false}
                showIndicators={false}
                swipeable={true}
                labels={""}
                interval={3500}
                max
            >
                    <div>
                        <img src={img2} />
                        <p className="legend">Legend 1</p>
                    </div>
                    <div>
                        <img src={img3} />
                        <p className="legend">Legend 2</p>
                    </div>
                    <div>
                        <img src={img4} />
                        <p className="legend">Legend 3</p>
                    </div>
                    <div>
                        <img src={img5} />
                        <p className="legend">Legend 3</p>
                    </div>
                    <div>
                        <img src={img1} />
                        <p className="legend">Legend 3</p>
                    </div>
                    <div>
                        <img src={img2} />
                        <p className="legend">Legend 3</p>
                    </div>
                </Carousel>

                <div className="containerItemBottom">

                <div className="containerItemArrow">
                    <MaterialIcon icon='expand_more' />
                </div>

                <div>

                </div>

                </div>

            </div>

            <div className="containerBody">

            <div className='pt-5 pb-5'>
                <Box component="form" className='container' noValidate sx={{ mt: 3 }}>
                <h1>Sobre Nós</h1>
                <h5 className='mt-5 mb-3'>Associação dos Amigos UNA VIVA</h5>


                <div className='containerAboutUs'>
                    <p className='aboutUs'>
                    

                    Somos uma associação devidamente registrada sem fins econômicos, cujo principal objetivo é atuar na preservação e sustentabilidade do Rio Una e sua Foz.
                    Nossos objetivos envolvem a propositura de ações e planos, conhecimento e educação ambiental, parcerias e colaboração com o Bairro e sociedade, exercício da cidadania ambiental e a garantia das condições de navegabilidade do rio, imprescindíveis a Barra do Una, que é hoje tida como um bairro Náutico com grande número de pessoas dependendo economicamente diretamente das boas condições do rio. 
                    Regidos por nosso estatuto, somos constituídos por colaboradores associados e buscamos apoio institucional.
                    Realizamos nossas reuniões junto ao campo de futebol ou outros lugares apropriados que nos sejam cedidos ou emprestados e mantemos nossa sede operacional em horário comercial. 
                    Mantemos comunicação por e-mail : <a href="mailto:adaunaviva@gmail.com">adaunaviva@gmail.com</a>, WhatsApp <a href='https://wa.me/5512996091831?text=Tenho%20interesse%20em%20me%20associar' target='_blank'>(12) 99609-1831</a> e pelo nosso website.
                    </p>

                    <div className='row'>
                        <a className='btnWhatsAppMe' href='https://wa.me/5512996091831?text=Tenho%20interesse%20em%20me%20associar' target='_blank'> 
                            <img width={25}  src={logoWhatsApp} /> Nos envie uma mensagem
                        </a>

                        <a className='btnWhatsAppMe' href='mailto:adaunaviva@gmail.com' target='_blank'> 
                            <img width={25}  src={logoMail} /> Nos envie uma mensagem
                        </a>
                    </div>

                </div>

                </Box>
            </div>






           
        

            </div>
        </div>

    );

}

export default Home;