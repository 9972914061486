import './../App.css';
import './AboutUs.css';
import logoWhatsApp from './../imagens/whatsapp-icone-4.png';
import logoMail from './../imagens/gmail-4561841_960_720.webp';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import img1 from './../imagens/2022-03-07.jpg';

function AboutUs() {
    return (
      <div>
        <div className='pt-5 pb-5'>
                <Box component="form" className='container mb-5' noValidate sx={{ mt: 3 }}>
                    <h1>Sobre Nós</h1>
                    
                    <Box className='row mt-4'>
                        <Box className='col-lg-6'>
                            
                            <h5 className='mt-5 mb-3'>Associação dos Amigos UNA VIVA</h5>

                            <div className='containerAboutUs'>
                                <p className='aboutUs'>
                                Somos uma associação devidamente registrada sem fins econômicos, cujo principal objetivo é atuar na preservação e sustentabilidade do Rio Una e sua Foz.
                                Nossos objetivos envolvem a propositura de ações e planos, conhecimento e educação ambiental, parcerias e colaboração com o Bairro e sociedade, exercício da cidadania ambiental e a garantia das condições de navegabilidade do rio, imprescindíveis a Barra do Una, que é hoje tida como um bairro Náutico com grande número de pessoas dependendo economicamente diretamente das boas condições do rio. 
                                Regidos por nosso estatuto, somos constituídos por colaboradores associados e buscamos apoio institucional.
                                Realizamos nossas reuniões junto ao campo de futebol ou outros lugares apropriados que nos sejam cedidos ou emprestados e mantemos nossa sede operacional em horário comercial. 
                                Mantemos comunicação por e-mail : <a href="mailto:adaunaviva@gmail.com">adaunaviva@gmail.com</a>, WhatsApp <a href='https://wa.me/5512996091831?text=Tenho%20interesse%20em%20me%20associar' target='_blank'>(12) 99609-1831</a> e pelo nosso website.
                                </p>

                                <div className='row'>

                                    <a className='btnWhatsAppMe' href='https://wa.me/5512996091831?text=Tenho%20interesse%20em%20me%20associar' target='_blank'> 
                                        <img width={25}  src={logoWhatsApp} /> Nos envie uma mensagem
                                    </a>

                                    <a className='btnWhatsAppMe' href='mailto:adaunaviva@gmail.com' target='_blank'> 
                                        <img width={25}  src={logoMail} /> Nos envie uma mensagem
                                    </a>
                                </div>
                            </div>
                        </Box>

                        <Box className='col-lg-6'>
                            <img className='imgAboutUs' src={img1} />
                        </Box>
                    </Box>
                </Box>



                <Box component="form" className='container mt-8' noValidate sx={{ mt: 6 }}>

                    <h2 className='mt-5 position-relative'>Nosso Estatuto</h2>
                    
                    <Box className='row mt-0'>
                        <Box className='col-lg-12'>
                            
                            <h5 className='mt-5 mb-3'>ESTATUTO DA ASSOCIAÇÃO DOS AMIGOS UNA VIVA</h5>

                            <div className='containerAboutUs'>
                                <p className='aboutUs'>
                                <p>Cap&iacute;tulo I</p>
                                    <p>Da denomina&ccedil;&atilde;o, sede e fins</p>
                                    <p><strong>Artigo 1&ordm; </strong>- A <strong>Associa&ccedil;&atilde;o dos Amigos Una Viva</strong>, constitu&iacute;da por Assembleia Geral realizada em 30 de setembro de 2021,com sede Rua Os&oacute;rio, n&ordm;. 2010 &ndash; Barra do Una &ndash; S&atilde;o Sebasti&atilde;o - S&atilde;o Paulo &ndash; CEP: 11624-206, &eacute; uma Associa&ccedil;&atilde;o de fins n&atilde;o econ&ocirc;micos e dura&ccedil;&atilde;o por tempo indeterminado e ser&aacute; regida pelo presente Estatuto e pelas demais disposi&ccedil;&otilde;es legais pertinentes.</p>
                                    <p><strong>Artigo 2&ordm; </strong>- A <strong>Associa&ccedil;&atilde;o dos Amigos Una Viva</strong>, tem por fim a elabora&ccedil;&atilde;o, a propositura de a&ccedil;&otilde;es, coordena&ccedil;&atilde;o e a implementa&ccedil;&atilde;o de planos, estudos, projetos, atividades e a&ccedil;&otilde;es relacionadas ao Rio Una e &agrave; sua foz localizada na praia de Barra do Una, na cidade de S&atilde;o Sebasti&atilde;o, Estado de S&atilde;o Paulo, que promovam e estimulem:</p>
                                    <ul>
                                    <li>A preserva&ccedil;&atilde;o e a sustentabilidade do Rio Una;</li>
                                    <li>O conhecimento e a educa&ccedil;&atilde;o ambiental;</li>
                                    <li>O aperfei&ccedil;amento das atividades n&aacute;uticas;</li>
                                    <li>A garantia de condi&ccedil;&otilde;es de navegabilidade;</li>
                                    <li>O comportamento &eacute;tico da sociedade;</li>
                                    <li>O exerc&iacute;cio da cidadania socio ambiental;</li>
                                    <li>A congrega&ccedil;&atilde;o dos interesses do Rio Una; e</li>
                                    <li>A defesa, judicial e extrajudicial, dos direitos difusos e coletivos relacionados com seus objetivos sociais abaixo descritos, bem como dos direitos individuais homog&ecirc;neos de seus associados, independentemente de aprova&ccedil;&atilde;o assemblear.</li>
                                    </ul>
                                    <p><strong>Par&aacute;grafo Primeiro</strong>: Para a realiza&ccedil;&atilde;o dos objetivos indicados neste artigo, a Associa&ccedil;&atilde;o poder&aacute; realizar bazares, feiras, bem como celebrar conv&ecirc;nios, contratos, acordos e termos de parceria com empresas privadas, empresas p&uacute;blicas e de economia mista, bem como com &Oacute;rg&atilde;os p&uacute;blicos, organiza&ccedil;&otilde;es, funda&ccedil;&otilde;es, entidades de classe, outras associa&ccedil;&otilde;es e institui&ccedil;&otilde;es financeiras p&uacute;blicas ou privadas, desde que o pacto n&atilde;o implique em sua subordina&ccedil;&atilde;o ou vincula&ccedil;&atilde;o a compromissos e interesses conflitantes com os objetivos da Associa&ccedil;&atilde;o, nem arrisque sua independ&ecirc;ncia.</p>
                                    <p><strong>Par&aacute;grafo Segundo</strong>: A Associa&ccedil;&atilde;o poder&aacute; receber doa&ccedil;&otilde;es, contribui&ccedil;&otilde;es, heran&ccedil;as, legados e qualquer outra modalidade de incentivo de pessoas f&iacute;sicas e jur&iacute;dicas, de direito p&uacute;blico ou privado, nacionais e estrangeiras, bem como aux&iacute;lios e subven&ccedil;&otilde;es governamentais, com vistas &agrave; consecu&ccedil;&atilde;o de seus objetivos e finalidades a que se destina.</p>
                                    <p><strong>Par&aacute;grafo Terceiro</strong>: A Associa&ccedil;&atilde;o poder&aacute; solicitar espa&ccedil;os emprestados para a realiza&ccedil;&atilde;o de Assembleias e reuni&otilde;es ou mesmo manter sua sede e escrit&oacute;rio , para o bom funcionamento.</p>
                                    <p><strong>Artigo 3&ordm; - </strong>No desenvolvimento de suas atividades, a <strong>Associa&ccedil;&atilde;o dos Amigos Una Viva</strong> observar&aacute; os princ&iacute;pios da legalidade, impessoalidade, moralidade, publicidade, economicidade e da efici&ecirc;ncia e n&atilde;o far&aacute; quaisquer discrimina&ccedil;&otilde;es, n&atilde;o admitindo controv&eacute;rsias de ra&ccedil;a, credo religioso, cor, g&ecirc;nero ou pol&iacute;tico-partid&aacute;rias em suas atividades, depend&ecirc;ncias ou em seu quadro de associados.</p>
                                    <p><strong>Artigo 4&ordm; </strong>- A Associa&ccedil;&atilde;o n&atilde;o remunera, sob qualquer forma, nenhum de seus associados, bem como n&atilde;o distribui lucros ou dividendos a qualquer t&iacute;tulo ou sob nenhum pretexto, sendo que eventuais excedentes operacionais ser&atilde;o integralmente aplicados no desenvolvimento dos objetivos da Associa&ccedil;&atilde;o.</p>
                                    <p><strong>Par&aacute;grafo &Uacute;nico</strong>: A Associa&ccedil;&atilde;o cobrar&aacute; de seus associados valores/taxas com o objetivo &uacute;nico e exclusivo de manter a sua finalidade, quais sejam, as elencadas no artigo 2&ordm;, incisos i ao viii.</p>
                                    <p><strong>Artigo 5&ordm; </strong>- A Associa&ccedil;&atilde;o poder&aacute; adotar um regimento interno para disciplinar seu funcionamento, devendo o mesmo ser submetido &agrave; aprova&ccedil;&atilde;o pela Assembleia Geral.</p>
                                    <p><strong>Artigo 6&ordm; </strong>- A Associa&ccedil;&atilde;o poder&aacute; organizar-se em tantas unidades quantas se fizerem necess&aacute;rias, a crit&eacute;rio da Assembleia Geral, as quais se reger&atilde;o por estas mesmas disposi&ccedil;&otilde;es estatut&aacute;rias.</p>
                                    <p><strong>CAP&Iacute;TULO II</strong></p>
                                    <p><strong>DOS ASSOCIADOS, DOS SEUS DIREITOS E DEVERES</strong></p>
                                    <p><strong>Artigo 7&ordm; </strong>- A Associa&ccedil;&atilde;o ser&aacute; constitu&iacute;da por um n&uacute;mero ilimitado de associados, distribu&iacute;dos nas seguintes categorias:</p>
                                    <ul>
                                    <li><strong>Fundadores</strong>: ser&atilde;o considerados fundadores os associados que participaram da Assembleia de Funda&ccedil;&atilde;o;</li>
                                    <li><strong>Colaboradores Associados</strong>: ser&atilde;o considerados colaboradores associados as pessoas f&iacute;sicas que contribu&iacute;rem, com a taxa m&iacute;nima mensal, trimestral ou anual, a qual ser&aacute; definida pelo Presidente e informada aos Associados, t&atilde;o logo seja poss&iacute;vel seu recolhimento em banco;</li>
                                    <li><strong>Honor&aacute;rio</strong>: as personalidades, em reconhecimento a relevantes servi&ccedil;os prestados &agrave; regi&atilde;o ou &agrave; Associa&ccedil;&atilde;o, devendo ser reconfirmados a cada tri&ecirc;nio;</li>
                                    <li><strong>Patrocinador:</strong> pessoa jur&iacute;dica ou f&iacute;sica que patrocine as atividades da Associa&ccedil;&atilde;o, de forma constante ou peri&oacute;dica; e</li>
                                    <li><strong>Institucional:</strong> pessoa jur&iacute;dica, p&uacute;blica ou privada, que venha a formar parceria ou conv&ecirc;nio com a Associa&ccedil;&atilde;o.</li>
                                    </ul>
                                    <p><strong>Par&aacute;grafo Primeiro</strong>: A pr&aacute;tica dos atos de associado deve ser feita pessoalmente, sendo admitida a representa&ccedil;&atilde;o por procurador.</p>
                                    <p><strong>Par&aacute;grafo Segundo</strong>: A qualidade de associado &eacute; intransmiss&iacute;vel e n&atilde;o gera para os herdeiros direitos patrimoniais.</p>
                                    <p><strong>Par&aacute;grafo Terceiro</strong>: Os associados n&atilde;o responder&atilde;o, solid&aacute;ria e nem subsidiariamente, pelas obriga&ccedil;&otilde;es ou compromissos de qualquer natureza contra&iacute;dos pela Associa&ccedil;&atilde;o.</p>
                                    <p><strong>Par&aacute;grafo Quarto</strong>: Os associados receber&atilde;o por e-mail e/ou <em>WhatsApp</em> o seu n&uacute;mero de inscri&ccedil;&atilde;o.</p>
                                    <p><strong>Artigo 8&ordm; - </strong>S&atilde;o direitos do associado:</p>
                                    <ol>
                                    <li>Votar e ser votado para os cargos eletivos da Diretoria e do Conselho Fiscal;</li>
                                    <li>Tomar parte nas Assembleias Gerais; e</li>
                                    </ol>
                                    <ul>
                                    <li>Demitir-se.</li>
                                    </ul>
                                    <p><strong>Par&aacute;grafo Primeiro</strong>: O exerc&iacute;cio dos direitos de associado est&aacute; condicionado ao cumprimento integral e regular dos deveres dispostos neste Estatuto.</p>
                                    <p><strong>Par&aacute;grafo Segundo </strong>&ndash; &Eacute; direito do associado demitir-se da Associa&ccedil;&atilde;o, a qualquer tempo, quando julgar necess&aacute;rio, mediante pedido junto &agrave; Diretoria da Associa&ccedil;&atilde;o, o que tamb&eacute;m poder&aacute; ser feito pelo meio eletr&ocirc;nico, qual seja, e-mail.</p>
                                    <p><strong>Artigo 9&ordm; </strong>&ndash; S&atilde;o deveres do associado:</p>
                                    <ol>
                                    <li>Respeitar e observar as regras deste Estatuto, as disposi&ccedil;&otilde;es regimentais e as delibera&ccedil;&otilde;es da Assembleia Geral;</li>
                                    <li>Cooperar com a consecu&ccedil;&atilde;o dos objetivos da Associa&ccedil;&atilde;o; e</li>
                                    </ol>
                                    <ul>
                                    <li>Comparecer nas Assembleias Gerais.</li>
                                    </ul>
                                    <p><strong>Artigo 10 &ndash; </strong>O associado que descumprir seus deveres e n&atilde;o observar as regras deste Estatuto estar&aacute; sujeito &agrave;s seguintes penalidades:</p>
                                    <ol>
                                    <li>Advert&ecirc;ncia;</li>
                                    <li>Exonera&ccedil;&atilde;o dos cargos e fun&ccedil;&otilde;es que exer&ccedil;a por elei&ccedil;&atilde;o ou nomea&ccedil;&atilde;o; e</li>
                                    </ol>
                                    <ul>
                                    <li>Exclus&atilde;o.</li>
                                    </ul>
                                    <p><strong>Par&aacute;grafo Primeiro: </strong>A exclus&atilde;o do associado ser&aacute; determinada quando ficar configurada a justa causa, assim reconhecida em procedimento que assegure direito de defesa e de recurso.</p>
                                    <p><strong>Par&aacute;grafo Segundo</strong>: A exclus&atilde;o do Associado n&atilde;o ensejar&aacute; dever de indeniza&ccedil;&atilde;o, tampouco dever de compensa&ccedil;&atilde;o a qualquer t&iacute;tulo.</p>
                                    <p><strong>Par&aacute;grafo Terceiro: </strong>As penalidades de advert&ecirc;ncia e suspens&atilde;o ser&atilde;o aplicadas aos Associados pela Diretoria.</p>
                                    <p><strong>Par&aacute;grafo Quarto:</strong> Quando o infrator for um membro da Diretoria e do Conselho Fiscal, as penalidades de advert&ecirc;ncia e suspens&atilde;o ser&atilde;o aplicadas pela Assembleia Geral.</p>
                                    <p><strong>Par&aacute;grafo Quinto:</strong> Considera-se falta grave, sujeita &agrave; penalidade de exclus&atilde;o, provocar ou causar preju&iacute;zo moral ou material &agrave; Associa&ccedil;&atilde;o.</p>
                                    <p><strong>Par&aacute;grafo Sexto: </strong>Das penalidades impostas, caber&aacute; recurso volunt&aacute;rio, no prazo de 05 (cinco) dias &uacute;teis, para a Assembleia Geral.</p>
                                    <p><strong>Par&aacute;grafo S&eacute;timo: </strong>O Colaborador Associado que deixar de realizar o pagamento da taxa m&iacute;nima mensal pelo prazo de 1 (um) ano, ser&aacute;, imadiatamente, exclu&iacute;do da Associa&ccedil;&atilde;o.</p>
                                    <p><strong>Par&aacute;grafo Oitavo</strong> - Ser&aacute; assegurado a todos os Associados amplo direito de defesa, bem como, o desligamento volunt&aacute;rio da Associa&ccedil;&atilde;o, notificando a Diretoria.</p>
                                    <p><strong>CAP&Iacute;TULO III</strong></p>
                                    <p><strong>DA ORGANIZA&Ccedil;&Atilde;O ADMINISTRATIVA </strong></p>
                                    <p><strong>Artigo 11 </strong>&ndash; A Associa&ccedil;&atilde;o exercer&aacute; suas atividades por meio dos seguintes &oacute;rg&atilde;os:</p>
                                    <ol>
                                    <li>Assembleia Geral;</li>
                                    <li>Diretoria; e</li>
                                    </ol>
                                    <ul>
                                    <li>Conselho Fiscal.</li>
                                    </ul>
                                    <p><strong>Artigo 12 </strong>&ndash; A <strong><u>Assembleia Geral</u></strong> &eacute; a inst&acirc;ncia m&aacute;xima decis&oacute;ria da Associa&ccedil;&atilde;o, sendo composta por todos os associados em pleno gozo de seus direitos, competindo-lhe deliberar sobre todos os atos relativos &agrave; Associa&ccedil;&atilde;o e tomar as decis&otilde;es que julgar convenientes &agrave; defesa e desenvolvimento do mesmo, sendo soberana nas resolu&ccedil;&otilde;es n&atilde;o contr&aacute;rias &agrave;s leis vigentes e a este Estatuto.</p>
                                    <p><strong>Artigo 13 </strong>&ndash; Compete &agrave; Assembleia Geral:</p>
                                    <ol>
                                    <li>Eleger, a cada 3 (tr&ecirc;s) anos, os membros da Diretoria (presidente, vice presidente) e do Conselho Fiscal , definindo suas fun&ccedil;&otilde;es, atribui&ccedil;&otilde;es e responsabilidades de acordo com o presente estatuto;</li>
                                    <li>Destituir os membros da Diretoria e do Conselho Fiscal;</li>
                                    </ol>
                                    <ul>
                                    <li>Excluir associados;</li>
                                    </ul>
                                    <ol>
                                    <li>Aplicar aos associados as penalidades previstas neste Estatuto;</li>
                                    <li>Decidir sobre a organiza&ccedil;&atilde;o de novas unidades da Associa&ccedil;&atilde;o;</li>
                                    <li>Deliberar e aprovar o plano de a&ccedil;&atilde;o e o or&ccedil;amento, anuais da Associa&ccedil;&atilde;o;</li>
                                    </ol>
                                    <ul>
                                    <li>Deliberar e aprovar as reformas e altera&ccedil;&otilde;es do presente Estatuto;</li>
                                    <li>Deliberar e aprovar a aquisi&ccedil;&atilde;o de bens im&oacute;veis pela Associa&ccedil;&atilde;o;</li>
                                    </ul>
                                    <ol>
                                    <li>Autorizar a aliena&ccedil;&atilde;o ou institui&ccedil;&atilde;o de &ocirc;nus sobre os bens pertencentes &agrave; Associa&ccedil;&atilde;o; e</li>
                                    <li>Deliberar sobre a dissolu&ccedil;&atilde;o da Associa&ccedil;&atilde;o em ato especificamente convocado para tal, a fim de que, como &oacute;rg&atilde;o m&aacute;ximo decis&oacute;rio, determine sobre a paralisa&ccedil;&atilde;o das atividades, fechamento da sede, continuidade do objeto social, sub-roga&ccedil;&atilde;o dos direitos e deveres de seus membros e destina&ccedil;&atilde;o de seus bens patrimoniais remanescentes.</li>
                                    </ol>
                                    <p><strong>Artigo 14 </strong>&ndash; A Assembleia Geral ser&aacute; ordin&aacute;ria ou extraordin&aacute;ria, podendo ser cumulativamente convocadas e realizadas.</p>
                                    <p><strong>Par&aacute;grafo Primeiro </strong>&ndash; A Assembleia Geral instalar-se-&aacute; ordinariamente, por convoca&ccedil;&atilde;o da Diretoria:</p>
                                    <ul>
                                    <li>No primeiro semestre de cada ano para: (<strong>i.i</strong>) Analisar o or&ccedil;amento e o desenvolvimento do plano de a&ccedil;&atilde;o; e (<strong>i.ii</strong>) Debater e deliberar sobre assuntos de interesse da Associa&ccedil;&atilde;o.</li>
                                    </ul>
                                    <ul>
                                    <li>No segundo semestre de cada ano para: <strong>(ii.i)</strong> Apresenta&ccedil;&atilde;o dos resultados alcan&ccedil;ados; <strong>(ii.ii)</strong> Apresenta&ccedil;&atilde;o do Plano de A&ccedil;&atilde;o e Or&ccedil;amento para o pr&oacute;ximo ano; <strong>(ii.iii)</strong> Apresenta&ccedil;&atilde;o do Balan&ccedil;o e aprova&ccedil;&atilde;o das contas; e <strong>(ii.iv)</strong> Debates e delibera&ccedil;&otilde;es sobre outros temas relevantes &agrave; Associa&ccedil;&atilde;o.</li>
                                    </ul>
                                    <ul>
                                    <li>A cada tr&ecirc;s anos para eleger os membros da Diretoria, do Conselho Fiscal e para reconfirmar o t&iacute;tulo dos associados Honor&aacute;rios.</li>
                                    </ul>
                                    <p><strong>Par&aacute;grafo Segundo &ndash; </strong>A Assembleia Geral reunir-se-&aacute;, extraordinariamente, a qualquer tempo, por motivos de relev&acirc;ncia e/ou urg&ecirc;ncia, quando convocada pela Diretoria, por requerimento de, pelo menos, 1/3 (um ter&ccedil;o) dos associados ou a pedido dos membros do Conselho Fiscal.</p>
                                    <p><strong>Artigo 15 </strong>&ndash; A Convoca&ccedil;&atilde;o dos associados para Assembleia Geral dar-se-&aacute; mediante edital afixado na sede da Associa&ccedil;&atilde;o com 15 (quinze) dias de anteced&ecirc;ncia, e/ou local claro e p&uacute;blico de passagem constante de seus associados (mural do bairro), e/ou por meio de circular impressa ou eletr&ocirc;nica entre os associados com o mesmo prazo de anteced&ecirc;ncia, em endere&ccedil;o a ser definido pela Diretoria e indicado aos associados.</p>
                                    <p><strong>Par&aacute;grafo Primeiro </strong>&ndash; As Assembleias Gerais instalar-se-&atilde;o em primeira convoca&ccedil;&atilde;o com a presen&ccedil;a de pelo menos 1/3 (um ter&ccedil;o) dos associados, e, em segunda convoca&ccedil;&atilde;o, trinta minutos ap&oacute;s, com qualquer n&uacute;mero, sendo as delibera&ccedil;&otilde;es feitas por metade mais um dos associados presentes.</p>
                                    <p><strong>Par&aacute;grafo Segundo </strong>&ndash; As Assembleias Gerais instalar-se-&atilde;o com a presen&ccedil;a de pelo menos 1/3 (um ter&ccedil;o) dos associados, sendo as delibera&ccedil;&otilde;es feitas por pelo menos 1/3 (um ter&ccedil;o) dos presentes quando tratarem das seguintes mat&eacute;rias: <strong>(i)</strong> Altera&ccedil;&atilde;o ou reforma total ou parcial do Estatuto; <strong>(ii)</strong> Exclus&atilde;o de associado; e <strong>(iii)</strong> Extin&ccedil;&atilde;o da Associa&ccedil;&atilde;o.</p>
                                    <p><strong>Par&aacute;grafo Terceiro </strong>&ndash; Nas Assembleias em que se pretenda a altera&ccedil;&atilde;o ou reforma total ou parcial do Estatuto, os associados que assim desejarem, poder&atilde;o enviar seus votos por carta ou e-mail.</p>
                                    <p><strong>Par&aacute;grafo Quarto </strong>&ndash; As Assembleia poder&atilde;o ficar abertas at&eacute; que se recebam os votos de pelo menos 1/3 (um ter&ccedil;o) dos associados, os quais poder&atilde;o, conforme par&aacute;grafo terceiro, acima, se dar por carta ou e-mail.</p>
                                    <p><strong>Artigo 16 </strong>&ndash; A <strong><u>Diretoria</u></strong> &eacute; um &oacute;rg&atilde;o administrativo e executor da Associa&ccedil;&atilde;o, colegiado e eleito pela Assembleia Geral, respons&aacute;vel pela representa&ccedil;&atilde;o institucional da Associa&ccedil;&atilde;o, sendo composto por um presidente, um vice- presidente, um secret&aacute;rio e um tesoureiro.</p>
                                    <p><strong>Par&aacute;grafo Primeiro </strong>&ndash; Compete &agrave; Diretoria:</p>
                                    <ul>
                                    <li>Zelar pelo fiel cumprimento do presente Estatuto e das delibera&ccedil;&otilde;es da Assembleia Geral e divulgar a Associa&ccedil;&atilde;o;</li>
                                    <li>Propor &agrave; Assembleia Geral as modifica&ccedil;&otilde;es que se fizerem necess&aacute;rias no Estatuto;</li>
                                    <li>Administrar a Associa&ccedil;&atilde;o;</li>
                                    <li>Aprovar e submeter &agrave; Assembleia Geral o plano de a&ccedil;&atilde;o e o or&ccedil;amento anuais da Associa&ccedil;&atilde;o, acompanhando sua execu&ccedil;&atilde;o;</li>
                                    <li>Periodicamente, conforme previs&atilde;o estatut&aacute;ria, convocar Assembleia Geral Ordin&aacute;ria;</li>
                                    <li>Deliberar sobre custos, despesas e encargos significativos n&atilde;o previstos no or&ccedil;amento anual;</li>
                                    <li>Convocar Assembleia Geral, a qualquer tempo, quando julgar necess&aacute;rio; e</li>
                                    <li>Assinar contratos e demais documentos que se fizerem necess&aacute;rios.</li>
                                    </ul>
                                    <p><strong>Par&aacute;grafo Segundo </strong>&ndash; A elei&ccedil;&atilde;o dos membros da Diretoria ser&aacute; realizada a cada 3 (tr&ecirc;s anos), em Assembleia Geral, sendo permitida a reelei&ccedil;&atilde;o de qualquer membro.</p>
                                    <p><strong>Par&aacute;grafo Terceiro </strong>&ndash; S&atilde;o expressamente vedados, sendo nulos e inoperantes, os atos de qualquer membro da Diretoria que envolvam a Associa&ccedil;&atilde;o em obriga&ccedil;&otilde;es ou neg&oacute;cios estranhos aos seus objetivos, finalidades e atividades.</p>
                                    <p><strong>Par&aacute;grafo Quarto </strong>&ndash; O trabalho desenvolvido pelos membros integrantes da Diretoria, com o fito de manter a Associa&ccedil;&atilde;o organizada e prestativa aos associados, ser&atilde;o remunerados (pr&oacute;-labore) da seguinte forma: <strong>(i)</strong> ao presidente e ao vice-presidente 1 sal&aacute;rio m&iacute;nimo vigente por m&ecirc;s; e <strong>(ii)</strong> ao secret&aacute;rio e tesoureiro &frac12; sal&aacute;rio m&iacute;nimo vigente por m&ecirc;s, n&atilde;o implicando, no entanto, em v&iacute;nculo empregat&iacute;cio ou obrigacional de qualquer natureza.</p>
                                    <p><strong>Par&aacute;grafo Quinto &ndash; </strong>A Diretoria reunir-se-&aacute; pelo menos uma vez ao ano para avalia&ccedil;&atilde;o de suas atividades e consecu&ccedil;&atilde;o dos fins planejados.</p>
                                    <p><strong>Par&aacute;grafo Sexto &ndash; </strong>Os membros da Diretoria poder&atilde;o ser destitu&iacute;dos desde que haja justa causa, definida esta em Assembleia Geral, em procedimento id&ecirc;ntico ao de exclus&atilde;o de associado, previsto neste Estatuto.</p>
                                    <p><strong>Artigo 17 </strong>&ndash; Compete ao <strong><u>Presidente</u></strong> da Diretoria:</p>
                                    <ul>
                                    <li>Zelar pelo fiel cumprimento do presente Estatuto e das delibera&ccedil;&otilde;es da Assembleia Geral e divulgar a Associa&ccedil;&atilde;o;</li>
                                    <li>representar a Associa&ccedil;&atilde;o ativa e passivamente, judicial e extrajudicialmente;</li>
                                    <li>Orientar as atividades da Associa&ccedil;&atilde;o, cumprindo e fazendo cumprir este Estatuto;</li>
                                    <li>Convocar e presidir Assembleias Gerais;</li>
                                    <li>Convocar as reuni&otilde;es da Diretoria que se fizerem necess&aacute;rias, bem como presid&iacute;-las; e</li>
                                    <li>Firmar, em nome da Associa&ccedil;&atilde;o, o aceite de doa&ccedil;&otilde;es, conv&ecirc;nios, termos de parceria, termos de compromisso, contratos, t&iacute;tulos e acordos de qualquer natureza.</li>
                                    </ul>
                                    <p><strong>Artigo 18 </strong>&ndash; Compete ao <strong><u>Vice&ndash;Presidente</u></strong> da Diretoria:</p>
                                    <ol>
                                    <li>Zelar pelo fiel cumprimento do presente Estatuto e das delibera&ccedil;&otilde;es da Assembleia Geral e divulgar a Associa&ccedil;&atilde;o;</li>
                                    <li>Substituir o presidente em sua falta ou em caso de impedimento;</li>
                                    </ol>
                                    <ul>
                                    <li>Assumir o mandato de Presidente, em caso de vac&acirc;ncia, at&eacute; o seu t&eacute;rmino; e</li>
                                    </ul>
                                    <ol>
                                    <li>Prestar, de modo geral, sua colabora&ccedil;&atilde;o ao Presidente para a consecu&ccedil;&atilde;o dos fins da Associa&ccedil;&atilde;o.</li>
                                    </ol>
                                    <p><strong>Artigo 19 </strong>&ndash; Compete ao <strong><u>Secret&aacute;rio</u></strong>:</p>
                                    <ul>
                                    <li>Zelar pelo fiel cumprimento do presente Estatuto e das delibera&ccedil;&otilde;es da Assembleia Geral e divulgar a Associa&ccedil;&atilde;o;</li>
                                    <li>Supervisionar as reuni&otilde;es da Diretoria e da Assembleia Geral;</li>
                                    <li>Supervisionar a elabora&ccedil;&atilde;o de relat&oacute;rios, organizar e dirigir as atividades da secretaria;</li>
                                    <li>Guardar e arquivar livros e documentos da esfera administrativa;</li>
                                    <li>Manter a Associa&ccedil;&atilde;o organizada nas suas formas de comunica&ccedil;&atilde;o por e-mail e/ou grupos de <em>WhatsApp/Telegram</em>, bem como atender aos associados no que for de praxe e condizente com os objetivos da Associa&ccedil;&atilde;o; e</li>
                                    <li>Praticar todos os demais atos atribu&iacute;dos pela presid&ecirc;ncia da Diretoria.</li>
                                    </ul>
                                    <p><strong>Artigo 20 </strong>&ndash; Compete ao <strong><u>Tesoureiro</u></strong>:</p>
                                    <ul>
                                    <li>Zelar pelo fiel cumprimento do presente Estatuto e das delibera&ccedil;&otilde;es da Assembleia Geral e divulgar a Associa&ccedil;&atilde;o;</li>
                                    <li>Supervisionar a elabora&ccedil;&atilde;o de relat&oacute;rios, organizar e dirigir as atividades da tesouraria, bem como cuidar do saldo banc&aacute;rio, das arrecada&ccedil;&otilde;es, e elaborar as planilhas de gastos e atividades.</li>
                                    <li>Manter a ficha de associados vigente apto &agrave; Associa&ccedil;&atilde;o e public&aacute;-la semestralmente; e</li>
                                    <li>Supervisionar os servi&ccedil;os de contabilidade;</li>
                                    </ul>
                                    <p><strong>Artigo 21 </strong>&ndash; Havendo vac&acirc;ncia de uma ou mais cargos da Diretoria, os substitutos ser&atilde;o eleitos por Assembleia Geral, especialmente convocada para este fim.</p>
                                    <p><strong>Artigo 22 </strong>&ndash; O <strong><u>Conselho Fiscal</u></strong> &eacute; um &oacute;rg&atilde;o colegiado, eleito pela Assembleia Geral, respons&aacute;vel pela fiscaliza&ccedil;&atilde;o da Diretoria, sendo composto por 2 (dois) membros efetivos e 2 (dois) membros suplentes.</p>
                                    <p><strong>Par&aacute;grafo Primeiro </strong>&ndash; Compete ao Conselho Fiscal:</p>
                                    <ul>
                                    <li>Zelar pelo fiel cumprimento do presente Estatuto e das delibera&ccedil;&otilde;es da Assembleia Geral e divulgar a Associa&ccedil;&atilde;o;</li>
                                    <li>Auxiliar e subsidiar a Diretoria em suas atribui&ccedil;&otilde;es;</li>
                                    <li>Opinar e aprovar os balan&ccedil;os, contas e relat&oacute;rios de desempenho financeiro e cont&aacute;bil e as opera&ccedil;&otilde;es patrimoniais realizadas;</li>
                                    <li>Analisar e fiscalizar as a&ccedil;&otilde;es da Diretoria e demais atos administrativos e financeiros; e</li>
                                    <li>Convocar Assembleia Geral, a qualquer tempo, quando necess&aacute;rio.</li>
                                    </ul>
                                    <p><strong>Par&aacute;grafo Segundo </strong>&ndash; A elei&ccedil;&atilde;o dos Membros do Conselho Fiscal ser&aacute; realizada a cada 3 (tr&ecirc;s) anos, em Assembleia Geral, sendo permitida a reelei&ccedil;&atilde;o de qualquer membro.</p>
                                    <p><strong>Par&aacute;grafo Terceiro &ndash; </strong>O Conselho Fiscal reunir-se-&aacute; pelo menos uma vez ao ano para avalia&ccedil;&atilde;o de suas atividades e consecu&ccedil;&atilde;o dos fins planejados.</p>
                                    <p><strong>Par&aacute;grafo Quarto </strong>&ndash; S&atilde;o expressamente vedados, sendo nulos e inoperantes, os atos de qualquer membro do Conselho Fiscal que envolvam a Associa&ccedil;&atilde;o em obriga&ccedil;&otilde;es ou neg&oacute;cios estranhos aos seus objetivos, finalidades e atividades.</p>
                                    <p><strong>Par&aacute;grafo Quinto &ndash; </strong>O trabalho desenvolvido pelos membros integrantes do Conselho Fiscal &eacute; gratuito, por livre e consciente disposi&ccedil;&atilde;o da vontade de cada membro, n&atilde;o implicando em v&iacute;nculo empregat&iacute;cio ou obrigacional de qualquer natureza.</p>
                                    <p><strong>Par&aacute;grafo Sexto &ndash; </strong>Os membros do Conselho Fiscal poder&atilde;o ser destitu&iacute;dos desde que haja justa causa, definida esta em Assembleia Geral, em procedimento id&ecirc;ntico ao de exclus&atilde;o de associado, previsto neste Estatuto.</p>
                                    <p><strong>Cap&iacute;tulo IV</strong></p>
                                    <p><strong>DOS RECURSOS FINANCEIROS E DO PATRIM&Ocirc;NIO</strong></p>
                                    <p><strong>Artigo 23 </strong>&ndash; Os recursos financeiros e o patrim&ocirc;nio da Associa&ccedil;&atilde;o prov&ecirc;m de:</p>
                                    <ul>
                                    <li>Contribui&ccedil;&otilde;es destinadas &agrave; manuten&ccedil;&atilde;o das atividades (taxa m&iacute;nima) e aos programas da Associa&ccedil;&atilde;o, decorrentes de acordos, contratos e termos de parceria firmados com empresas p&uacute;blicas ou privadas;</li>
                                    <li>Doa&ccedil;&otilde;es, heran&ccedil;as, legados e outras contribui&ccedil;&otilde;es de pessoas f&iacute;sicas ou jur&iacute;dicas, nacionais ou estrangeiras; e</li>
                                    <li>Rendimentos produzidos por todos os bens, direitos e atividades realizadas para a consecu&ccedil;&atilde;o dos objetivos institucionais, tais como, mas n&atilde;o apenas, receitas e aplica&ccedil;&otilde;es financeiras, presta&ccedil;&atilde;o de servi&ccedil;os, comercializa&ccedil;&atilde;o de produtos e rendimentos oriundos de direitos autorais.</li>
                                    </ul>
                                    <p><strong>Artigo 24 </strong>&ndash; Todo material permanente, acervo t&eacute;cnico, bibliogr&aacute;fico, equipamentos adquiridos ou recebidos pela Associa&ccedil;&atilde;o em conv&ecirc;nios, projetos ou similares, incluindo qualquer produto, s&atilde;o bens permanentes da Institui&ccedil;&atilde;o e inalien&aacute;veis, salvo autoriza&ccedil;&atilde;o em contr&aacute;rio expressa da Assembleia Geral.</p>
                                    <p><strong>Par&aacute;grafo Primeiro: </strong>Os bens da Associa&ccedil;&atilde;o n&atilde;o poder&atilde;o ser onerados, permutados ou alienados sem autoriza&ccedil;&atilde;o da Assembleia Geral convocada especialmente para este fim.</p>
                                    <p><strong>Par&aacute;grafo Segundo</strong>: As despesas da Associa&ccedil;&atilde;o dever&atilde;o guardar estreita e espec&iacute;fica rela&ccedil;&atilde;o com suas finalidades.</p>
                                    <p><strong>Par&aacute;grafo Terceiro</strong>: Os recursos e patrim&ocirc;nio da Associa&ccedil;&atilde;o ser&atilde;o integralmente aplicados no pa&iacute;s.</p>
                                    <p><strong>CAP&Iacute;TULO V</strong></p>
                                    <p><strong>DAS DISPOSI&Ccedil;&Otilde;ES GERAIS E TRANSIT&Oacute;RIAS</strong></p>
                                    <p><strong>Artigo 25 </strong>&ndash; A Associa&ccedil;&atilde;o poder&aacute; ser dissolvida por decis&atilde;o da Assembleia Geral, em convoca&ccedil;&atilde;o extraordin&aacute;ria, observadas as disposi&ccedil;&otilde;es do artigo 61 do C&oacute;digo Civil, e, neste caso, seu patrim&ocirc;nio ser&aacute; destinado a institui&ccedil;&otilde;es similares, preferencialmente que tenham os mesmos objetivos e finalidades desta Associa&ccedil;&atilde;o.</p>
                                    <p><strong>Artigo 26 </strong>&ndash; Os casos omissos neste Estatuto ser&atilde;o analisados e resolvidos pela Diretoria e referendados pela Assembleia Geral.</p>
                                    <p><strong>Artigo 27 </strong>&ndash; O Presidente da Diretoria est&aacute; autorizado a proceder ao registro deste Estatuto.</p>
                                    <p>O presente Estatuto foi votado e aprovado na Assembleia Geral realizada em 30 de setembro de 2021, entrando em vigor a partir da data de seu registro.</p>
                                </p>



                            </div>
                        </Box>

                       
                    </Box>
                </Box>
            </div>
      </div>
    );
  }
  export default AboutUs;