import React from "react";
import logo from './LogoUna-removebg.png';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import Typography from '@mui/material/Typography';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Associese from "./pages/Associese";

function Copyright(props) {
  return (
    <footer>
      <div className="container footerItens">

        <div className="row">
          <div className="col-lg-6">

            <ul className="listMenu">
              <li><a href="/">Home</a></li>
              <li><a href="/sobrenos">Sobre nós</a></li>
              <li><a href="/associar">Associar-se</a></li>
            </ul>

          </div>
          <div className="col-lg-6 d-flex">
            <img className="footerLogo" src={logo}></img>
          </div>
        </div>


      </div>

      <Box component="div" className='container' noValidate sx={{ mt: 3 }}>
        <Typography variant="body2" className="containerCopy" color="text.secondary" align="center" {...props}>
          <a color="inherit" href="https://unaviva.com.br/">
            Associação dos amigos una viva - 47.596.852/0001-57
          </a> - {new Date().getFullYear()}
        </Typography>
      </Box>
    </footer>
  );
}

const theme = createTheme({palette: {
  primary: {
    main: "#dd9400",
  },
},});

function App() {
  
  return (
    <div className='App' >
      <ThemeProvider
        theme={theme}
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs"
      >
        <Router>
          <header className="App-header">
            <div className="container">

              <img src={logo} className="App-logo" alt="logo" />

              <div className="itemsMenu">
                  <button onClick={() => window.location.href = '/' } >Home</button>
                  <button onClick={() => window.location.href = '/sobrenos' } >Sobre nós</button>
                  <button onClick={() => window.location.href = '/associar' } >Como se associar</button>
              </div> 
            </div>

          </header>
          
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/sobrenos" element={<AboutUs />} />
              <Route path="/associar" element={<Associese />} />
          </Routes>
        </Router>
        <Copyright sx={{ mt: 5 }} />


      </ThemeProvider>       
    </div>



  );
}

export default App;
