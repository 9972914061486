import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import { IMaskInput } from 'react-imask';
import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';




function Associese() {

    
    const { handleSubmit, formState } = useForm()
    const { isSubmitting } = formState
    function submitForm(data) {
        data = new FormData(document.getElementById('form'))
        
        return new Promise((resolve) => {

        setTimeout(() => {

            const dataSendMail = {
                NomeCompleto:data.get('NomeCompleto'),
                telefone:data.get('telefone'),
                email:data.get('email'),
                nacionalidade:data.get('nacionalidade'),
                estadocivil:data.get('estadocivil'),
                profissao:data.get('profissao'),
                CPF:data.get('CPF'),
                RG:data.get('RG'),
                dataNascimento:data.get('dataNascimento'),
                endereco:data.get('endereco'),
                numero:data.get('numero'),
                complemento:data.get('complemento'),
                bairro:data.get('bairro'),
                cidade:data.get('cidade'),
                estado:data.get('estado'),
                CEP:data.get('CEP')
            }

            fetch("http://localhost:5000/send",{
                method:"post",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify(dataSendMail)
            })
            .then(res=>res.json())
            .then(data => {
            
                document.getElementById('form').style = 'display:none';
                document.getElementById('tittle').innerText = 'Solicitação de associação enviada !';
                document.getElementById('subTitle').innerText = 'Sua solicitação esta sendo processada, em breve você receberá uma confirmação por e-mail, podemos entrar em contato pelo telefone informado.';
                
            }).catch(err => {
                console.log(err)
            })

            

            resolve()
        }, 4000)
        })
    }

   

    return(

        <>
         <div className='pt-5'>
                <h1 id="tittle">Deseja se Associar?</h1>
                <h5 id="subTitle" className='mt-5 mb-3'>Preencha a ficha de inscrição abaixo e você receberá a confirmação por e-mail</h5>
            </div>


            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
         
            <Box id="form" component="form" className='container' noValidate onSubmit={handleSubmit(submitForm)} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <TextField
                    autoComplete="given-name"
                    name="NomeCompleto"
                    required
                    fullWidth
                    id="NomeCompleto"
                    label="Nome Completo"
                    autoFocus
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    required
                    fullWidth
                    name="telefone"
                    label="Telefone"
                    id="telefone"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    required
                    fullWidth
                    id="email"
                    type="email"
                    label="Endereço de e-mail"
                    name="email"
                    autoComplete="email"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    required
                    fullWidth
                    name="nacionalidade"
                    label="Nacionalidade"
                    id="nacionalidade"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    required
                    fullWidth
                    name="estadocivil"
                    label="Estado Civil"
                    id="estadocivil"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    required
                    fullWidth
                    name="profissao"
                    label="Profissão"
                    id="profissao"
                    />
                </Grid>
                
                <Grid item xs={6}>
                    <TextField
                    required
                    fullWidth
                    name="CPF"
                    label="CPF"
                    id="CPF"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    required
                    fullWidth
                    name="RG"
                    label="RG"
                    id="RG"
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                    required
                    fullWidth
                    name="dataNascimento"
                    label="Data de Nascimento"
                    id="dataNascimento"
                    />
                </Grid>

                <Grid item xs={7}>
                    <TextField
                    required
                    fullWidth
                    name="endereco"
                    label="Endereço"
                    id="endereco"
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                    required
                    fullWidth
                    name="numero"
                    label="Numero"
                    id="numero"
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                    required
                    fullWidth
                    name="complemento"
                    label="Complemento"
                    id="complemento"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    required
                    fullWidth
                    name="bairro"
                    label="Bairro"
                    id="bairro"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    required
                    fullWidth
                    name="cidade"
                    label="Cidade"
                    id="cidade"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    required
                    fullWidth
                    name="estado"
                    label="Estado"
                    id="estado"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    required
                    fullWidth
                    name="CEP"
                    label="CEP"
                    id="CEP"
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <FormControlLabel
                    required
                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                    label="Venho, através deste, requerer a V.S.ª minha admissão como Associado da ASSOCIAÇÃO DOS AMIGOS UNA VIVA –, conforme o que preceitua o Estatuto da Associação.

                    Para este fim, declaro conhecer o Estatuto da Associação, o qual está disponível para todos os associados, bem como respeitá-lo em toda a sua plenitude.
        
                    Por ser verdade, ratifico e assino todas as declarações por mim prestadas neste requerimento."
                    />
                </Grid>
                </Grid>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isSubmitting}
                >
                 {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                 Afiliar-se
                </Button>
                
            </Box>


            <Typography component="label">
                <div className="container">
                
                </div>

            </Typography>

            </Box>
        </>
    );
}

export default Associese;
